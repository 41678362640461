<template>
  <component
    id="fr-consent_finished-model"
    :is="createdComponent"
    :viewVariables="{ consents: dataObject.consents, consentsMap, isValid }"
    @submit="submit"
  />
</template>

<script>
import { getModelResources } from "@/utils/modelUtils";
import { createComponentData, createModelComponent, htmlVariableReplace } from "@/utils/utils";
import { sendConsents } from "@/services/generalService";

export default {
    name: "ConsentWrapper",

    props: {
      dataObject: Object
    },

    computed: {
      isValid() {
        return Object.entries(this.consentsMap).every(([consentId, value]) => {
          const allow_no_response = this.dataObject.consents.find(consent => consent.id === consentId).allow_no_response
          return parseInt(value) > 0 || (allow_no_response && parseInt(value) === -1)
        })
      }
    },

    data() {
      return {
        createdComponent: null,
        consentsMap: {} // -1 no contestado, 0 rechazado, 1 aceptado
      }
    },

    async created() {
      const modelResources = await getModelResources(this.dataObject.typology, this.dataObject.model)
      this.html = htmlVariableReplace(modelResources.html, modelResources.modelObject.variables, this.dataObject.mapping)
      this.modelObject = modelResources.modelObject
      const componentData = createComponentData(this.modelObject.requestVariables)

      this.createdComponent = createModelComponent(this.html, componentData)

      this.consentsMap = Object.fromEntries(
        this.dataObject.consents.map(consent => [consent.id, -1])
      );

      this.$emit("componentLoaded")
    },

    methods: {
      async submit() {
        const { data } = await sendConsents(this.dataObject.currentHash, { consents: this.consentsMap })

        console.log("dd", data)

        if (data.message === "ok") {
          location.reload()
        }

      }
    }

  }
</script>

<style scoped lang="scss">

</style>